import { hasAtlasCompaniesExceptInsurors } from "../forms/utils/quotes";
import {
  hasColonialStateWide,
  isColonial,
  checkIfIsAtlasCompany,
  hasInsurorsIndemnity
} from "@/forms/utils";

export function validateYearBuilt(
  value: number,
  companyNumbers: Array<number>
) {
  const response = {
    success: true,
    message: "",
    reason: ""
  };
  const message = "Call Underwriter for Approval";
  if (isColonial(companyNumbers)) {
    if (hasColonialStateWide(companyNumbers) && value < 1950) {
      response.success = false;
      response.reason = "Year built is less than 1950";
      response.message = message;
      return response;
    } else if (!hasColonialStateWide(companyNumbers) && value < 1965) {
      response.success = false;
      response.reason = "Year built is less than 1965";
      response.message = message;
      return response;
    }
  }

  if (checkIfIsAtlasCompany(companyNumbers)) {
    const today = new Date();
    const yearNow = today.getFullYear();
    const yearDifference = yearNow - value;
    if (hasInsurorsIndemnity(companyNumbers) && value <= 1972) {
      response.success = false;
      response.reason = "Year built is less than 1973";
      response.message = message;
      return response;
    } else if (
      hasAtlasCompaniesExceptInsurors(companyNumbers) &&
      yearDifference > 45
    ) {
      response.success = false;
      response.reason = "Year built is more than 45 years";
      response.message = message;
      return response;
    }
  }
  return response;
}
