
import Vue from "vue";
import { capitalizeFirstLetter } from "@/helpers/generalHelpers";
export default Vue.extend({
  name: "mortgageToolTip",
  props: {
    row: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    processName(): string {
      return capitalizeFirstLetter(this.row.mortgageName) || "N/A";
    },
    toolTipContent(): string {
      const mortgageName = `${this.row.shortName || "N/A"}`;
      return capitalizeFirstLetter(
        `${mortgageName} - AI Code:${this.row.AICode}  `
      );
    }
  }
});
